.login {
  background: #424242;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 100px;
  position: absolute; }
  .login .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
    height: calc(2.0625rem + 2px); }
  .login .input-username {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
  .login__container {
    position: relative;
    z-index: 4;
    width: 100%; }
  .login__form {
    width: 100%;
    padding: 0 30px 40px 30px; }
    .login__form--input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
      .login__form--input::placeholder {
        color: #bfbfbf;
        font-size: 14px; }
  .login__background {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #022f37;
    position: absolute;
    z-index: 2; }
    .login__background img {
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0.75;
      object-fit: cover;
    }

.validation-color h3 {
  color: black !important; }
.errors-message {
  color: red;
  text-align: left;
  font-size: 15px;
  white-space: normal;
  margin: 0px 0px 0.5rem;
}
@media only screen and (max-width: 600px) {
  .login {
    padding: 0; }
    .login__container {
      width: 100%;
      border: none; } }

@media only screen and (max-width: 800px) and (min-width: 601px) {
  .login__container {
    width: 70%; } }

.c-header {
  text-align: center;
}