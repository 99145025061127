
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: lightblue; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background:lightblue; 
  }

  .container-div {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    background-color: white;
    display: none;
    position: absolute;
    width: 100%;
    max-height: 200px; 
    box-shadow: 12px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 0px 5px 16px 16px;
    margin-left: -10px;
    margin-top: 10px;
    z-index: 1;
    overflow-y: scroll;
  }

  .dropdown-content-show {
    background-color: white;
    display: block;
    position: absolute;
    width: 100%;
    max-height: 200px; 
    box-shadow: 12px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 0px 5px 16px 16px;
    margin-left: -10px;
    margin-top: 10px;
    z-index: 1;
    overflow-y: scroll;
  }

  .no-options {
    text-align: center;
    color: #999;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  input::placeholder {
    color: lightgray;
  }