.my-request {
    &__table {
      border-collapse: collapse;
      width: 100%;
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      &__filter {
        padding-bottom: 1.25rem;
      }
    }
    &__content {
      width: 100%;
    }
    &__pagination {
      margin: 40px 0px;
      height: 35px;
      ul {
        flex-direction: row;
        justify-content: flex-end;
      }
      .disabled {
        a {
          cursor: not-allowed;
        }
        cursor: not-allowed;
      }
      .active {
        a {
          color: white;
          background-color: #20A8D8;
          border-color: #20A8D8;
        }
      }
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        a {
          color: #73818f;
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 1;
          width: 35px;
          height: 35px;
          background-color: #fff;
          border-top: 1px solid #c8ced3;
          border-left: 1px solid #c8ced3;
          border-bottom: 1px solid #c8ced3;
          flex-direction: column;
          text-decoration: none;
          border-color: #c8ced3;
        }
        &:first-child {
          a {
            border-top-left-radius: .25rem;
            border-bottom-left-radius: .25rem;
          }
        }
        &:last-child {
          a {
            border-top-right-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
            border-right: 1px solid #c8ced3;
          }
        }
        &:not(.active) {
          a:hover {
            background-color: #eee;
          }
        }
        // &:not(.disabled) {
        //   a:hover {
        //     background-color: #eee;
        //   }
        // }
      }
    }
  }
  
  .table-item__action {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      margin-right: 5px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  .select-perpage {
    width: 100%;
  }
  .select-pagination {
    width: 100px;
    &__top {
      width: 100%;
    }
  }
  
  .offset-1{
    margin-left: 0px;
  }
  
  .select-perpage__info {
    display: flex;
    align-items: baseline;
    p {
      margin-left: 30px;
    }
  }