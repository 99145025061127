.button-edit{
    margin: 10px;
}

.div-table{
    background-color: white
}

.table-row{
    width: 100%
}

.table-column{
    width: 300px;
}

.none-border{
    width: 100%;
}

.select-employee{
    width: 100%;
    float: right;
}

.title_employee_hub{
    flex-direction: row;
    height: 40px;
}
.title_employee_hub span {
    line-height: 40px
}

.tutorial{
    float: right;
    margin-right: 100px;
}

.text-empty-meployee{
    float: center;
    text-align: center;
    font-size: 10;
    color: gray
}

.employee-hub-body{
    background-color: white;
}

.pagination nav {
    margin-right: auto;
    position: absolute;
    right: 0;
    margin-right: 15px;
}

.slected-footer-employe{
    width: 50px;
}

.employee-hub .position-relative{
    margin-bottom: 0
}

.row_table_report_history{
    width: 100px
}

.date-picker-employeehub{
    padding:10px;
    justify-content: center;
    text-align: center
}

#react-paginate ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
}

#react-paginate li {
    display: inline-block;
}
.row-1{
    width: 300px;
}
.none-border{
    width: 100%;
}
.pd10 {
    padding: 10px;
}
.gp-employee {
    margin-bottom: 20px;
}
.gp-employee h5 {
    margin-bottom: 20px;
}
.gp-employee p {
    margin-bottom: 0.5rem;
}
.gp-employee td {
    min-width: 300px;
}

.slect-limit-report-history{
    display: inline-block;
}

.count-elemet-item-page{
    display: inline-block;
    float: right;
    line-height: 55px;
}




@media screen and (max-width: 800px) {
    .card-table {
      overflow-x: scroll;
    }
  }
  

