html body .app.flex-row.align-items-center {
  height: 100vh;
}
.rt-table {
  .rt-td {
    justify-content: left;
    &:last-child {
      justify-content: center;
    }
  }
}