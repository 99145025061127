.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: .25rem;
  box-sizing: border-box;
}

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination .page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.page-item.disabled .page-link {
  color: #73818f;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #c8ced3;
}

.pagination .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #20a8d8;
  border-color: #20a8d8;
}

.pagination .page-item.active {
  cursor: auto !important;
}

.pagination .page-item:not(:disabled):not(.disabled) {
  cursor: pointer;
}

