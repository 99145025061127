.table-common {
  .thead {
    box-shadow: initial !important;
    border-bottom: 1px solid #ddd;
    font-weight: 700;
    .th {
      height: 40px;
      line-height: 30px !important;
      text-align: left;
      padding-left: 1em !important;
      &.tb-action{
        width: 170px !important;
        max-width: 170px !important;
      }
      &:first-child {
        width: 170px !important;
        max-width: 170px !important;
      }
      &.-sort-asc {
        box-shadow: initial !important;
        &:before {
          opacity: 1;
        }
      }
      &.-sort-desc {
        box-shadow: initial !important;
        &:after {
          opacity: 1;
        }
      }
    }
  }
  tbody {
    td {
      border-right: 1px solid rgba(0,0,0,0.05) !important;
      border-bottom: 1px solid rgba(0,0,0,0.05) !important;
      &:last-child {
        border-right: 0px solid rgba(0,0,0,0.05) !important;
      }
      &.tb-action {
        width: 170px !important;
        max-width: 170px !important;
      }
      &:first-child {
        width: 170px !important;
        max-width: 170px !important;
      }
    }
  }
}
.btn [class^="icon-"], .btn [class*=" icon-"] {
  font-size: 14px;
}
.employee-list {
  Button {
    margin: 0 2px;
  }
}
.header-common {
  width: 50%;
  display: inline-block;
}
.search-right {
  float: right;
}
.ReactTable.col-12 {
  padding-left: 0;
  padding-right: 0;
}
.react-datepicker-wrapper {
  width: 100%;
  .react-datepicker__input-container {
    width: 100%;
  }
  input {
    padding-right: 40px !important;
    display: block;
    width: 100%;
    height: calc(2.0625rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #5c6873;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}
.full-width {
  width: 100%;
}
.multi-select {
  height: 37px;
}
.text-bold {
  font-size: 18px;
  font-weight: 700;
}
.search-right {
  position: relative;
  margin-bottom: 20px;
  .card-header-action {
    position: absolute;
    top: 6px;
    right: 45px;
    z-index: 9;
    border: 0;
    background-color: transparent;
  }
  input {
    padding-right: 35px;
  }
}
.input-group {
  &:hover {
    .datepicker-common {
      input {
        background: transparent !important;
      }
    }
    input {
      background-color: #f0f3f5 !important;
    }
  }
}
.css-vj8t7z {
  &:hover {
    background-color: #f0f3f5 !important;
  }
}
.form-group {
  textarea {
    &:hover {
      background-color: #f0f3f5 !important;
    }
  }
}
body {
  .react-confirm-alert-overlay {
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999;
  }
  .react-confirm-alert-body {
    text-align: center;
    word-break: break-word;
  }
  .react-confirm-alert-body {
    .react-confirm-alert-button-group {
      display: block;
      text-align: center;
    }
  }
  .Table__pagination {
    padding: 44px 10px;
  }
  .page-per-size {
    width: 100px;
    float: left;
    padding: 35px 10px;
    padding-left: 0;
    .select-pagination {
      width: 100%;
    }
  }
  .Table__pagination {
    float: right;
  }
}
.select-pagination {
  width: 100px;
}
.report-member {
  tr {
    th {
      vertical-align: middle;
    }
  }
}

.rt-resizable-header {
  cursor: pointer;
  position: relative;
  &:before {
    right: 1.3em;
    content: "\2191";
    position: absolute;
    bottom: .3em;
    display: block;
    opacity: .3;
  }
  &:after {
    right: .6em;
    content: "\2193";
    position: absolute;
    bottom: .3em;
    display: block;
    opacity: .3;
  }
  &.disable-sort {
    text-align: center !important;
    &:before, &:after {
      display: none;
    }
  }
}

.employee-datepicker {
  position: relative;
}
.datepicker-common {
  width: 100%;
  font-family: inherit;
  &:before {
    position: absolute;
    right: 25px;
    top: 6px;
    font-family: 'simple-line-icons';
  }
  .react-datepicker-wrapper {
    input {
      background: transparent;
    }
  }
}
.datepicker-uncommon {
  width: 100%;
  font-family: inherit;
  &:before {
    position: absolute;
    right: 50px;
    top: 6px;
    font-family: 'simple-line-icons';
  }
  .react-datepicker-wrapper {
    input {
      background: transparent;
    }
  }
}
.css-15k3avv {
  z-index: 999 !important;
  text-align: left;
}
.navbar-brand {
  img {
    width: 103px;
    height: 37px;
  }
}
textarea {
  &.form-control {
    height: 100px;
  }
}
.project-hub {
  .form-group {
    margin-bottom: 0;
  }
}
.project-hub-title {
  line-height: 40px;
  font-size: 18px;
  font-weight: 700;
  padding-left: 0;
}
.css-vj8t7z {
  min-height: 40px !important;
}
.permission-title {
  word-break: break-word;
}
.account-settings-pointer:hover, .account-settings-pointer:hover label, .account-settings-pointer:hover input {
  cursor: pointer;
}
p, td {
  word-break: break-word;
}

.toast-success {
  background-color: #51A351 !important;
}
.toast-error{
  background-color:#BD362F !important;
}
.toast-info{
  background-color:#2F96B4 !important;
}
.toast-warning{
  background-color:#F89406 !important;
}
table {
  .css-xp4uvy {
    margin-right: 0;
    margin-left: 0;
  }
  td {
    input, textarea {
      &:hover {
        background-color: #f0f3f5;
      }
    }
  }
}
table .td-edit {
  padding: 0.5rem;
  width: 160px;
  min-width: 160px;
}
.btn-edit {
  button {
    background-color: initial;
    border: 0;
    padding-left: 0;
    .icon-note {
      font-size: 18px;
      margin-right: 5px;
      font-weight: 700;
    }
    &:hover {
      background-color: initial;
    }
  }
}
.delete-button {
  margin-top: 5px;
}

.my-report-history {
  th, td {
    height: 67px;
    min-height: 67px;
  }
}
.lineHeight-th {
  line-height: 30px;
}

// Authentication
.reset-password-ul {
  padding-left: 0;
  list-style: none;
}

.gp-employee-list {
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 20px;
  h5 {
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .datepicker-common {
    margin-bottom: 0;
  }
}
.gp-project, .gp-employee {
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
}

.employee-hub-body, .project-hub {
  td, th {
    height: 46px;
  }
}
.my-report-history {
  th.table-th {
    line-height: 20px;
    height: 30px;
  }
}
.textarea-none {
  white-space: pre-line;
  width: 300px;
  min-width: 300px;
  word-break: break-word;
}

.login-account {
  .ReactTable {
    .rt-thead {
      .rt-th {
        &:first-child {
          width: 170px !important;
          max-width: 170px !important;
        }
      }
    }
    .rt-tbody {
      .rt-td {
        &:first-child {
          width: 170px !important;
          max-width: 170px !important;
        }
      }
    }
  }
}

.btn-author {
  margin-right: 30px;
  i {
    margin-right: 10px;
    font-size: 16px !important;
  }
  button {
    background-color: transparent;
    border: 0;
    font-size: 16px;
  }
}
.table tbody + tbody {
  border: 0;
}
@-moz-document url-prefix() {
  table {
    border-collapse: inherit;
    border-spacing: 0;
  }
}
.table-common {
  thead {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    tr {
      flex: 1 0 auto;
      display: inline-flex;
      th {
        flex: 100 0 auto;
        width: 100px;
        padding: 5px;
        height: 40px;
        line-height: 30px;
        border-right: 1px solid rgba(0, 0, 0, 0.05) !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
        border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &:first-child {
          width: 170px !important;
          max-width: 170px !important;
          border-left: 1px solid rgba(0, 0, 0, 0.05) !important;
        }
        &.tb-action {
          width: 170px !important;
          max-width: 170px !important;
          border-right: 1px solid rgba(0, 0, 0, 0.05) !important;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  tbody {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    tr {
      flex: 1 0 auto;
      display: inline-flex;
      td {
        vertical-align: middle;
        text-overflow: ellipsis;
        padding: 7px 5px;
        overflow: hidden;
        -webkit-transition: .3s ease;
        transition: .3s ease;
        -webkit-transition-property: width,min-width,padding,opacity;
        transition-property: width,min-width,padding,opacity;
        border-right: 1px solid rgba(0, 0, 0, 0.05) !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
        border-top: 0;
        flex: 100 0 auto;
        width: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &:first-child {
          width: 170px !important;
          max-width: 170px !important;
          border-left: 1px solid rgba(0, 0, 0, 0.05) !important;
        }
        &.tb-action {
          width: 170px !important;
          max-width: 170px !important;
          border-right: 1px solid rgba(0, 0, 0, 0.05) !important;
        }
      }
      &:nth-of-type(odd) {
        background: rgba(0,0,0,0.03);
      }
      &:hover {
        background: rgba(0,0,0,0.05);
      }
    }
  }
  .employee-list {
    text-align: center;
  }
}

.sortby-th.asc{
  &:before {
    right: 1.3em;
    content: "\2191";
    position: absolute;
    bottom: .3em;
    display: block;
    opacity: 1;
  }
  &:after {
    right: .6em;
    content: "\2193";
    position: absolute;
    bottom: .3em;
    display: block;
    opacity: .3;
  }
}

.sortby-th.desc{
  &:before {
    right: 1.3em;
    content: "\2191";
    position: absolute;
    bottom: .3em;
    display: block;
    opacity: .3;
  };
  &:after {
    right: .6em;
    content: "\2193";
    position: absolute;
    bottom: .3em;
    display: block;
    opacity: 1;
  }
}


.sortby-th {
  position: relative;
  &:before {
    right: 1.3em;
    content: "\2191";
    position: absolute;
    bottom: .3em;
    display: block;
    opacity: .3;
  };
  &:after {
    right: .6em;
    content: "\2193";
    position: absolute;
    bottom: .3em;
    display: block;
    opacity: .3;
  }
}
.css-bg1rzq-control, .react-datepicker-wrapper {
  &:hover {
    background-color: #f0f3f5;
  }
}
.fs-14 {
  font-size: 14px;
}
.my-request__table__filter {
  .dropdown-menu {
    padding: 0 !important;
    button {
      padding-left: 15px !important;
    }
  }
}
.request-verdict {
  p {
    height: 28px;
    line-height: 28px;
  }
}
.select-style {
  display: block;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.menu_center {
  .dropdown-menu {
    padding: 0 !important;
    button {
      text-align: center;
    }
  }
}
.request-status {
  p {
    height: 38px;
    line-height: 38px;
  }
}
.tab-employee {
  border-bottom: 0;
  .nav-item {
    width: 50%;
    text-align: center;
    .nav-link {
      border: 1px solid #c8ced3;
      border-radius: 0;
      &.active {
        background-color: #63c2de;
        color: #fff;
        border: 1px solid #63c2de;
      }
      &:hover {
        color: #2f353a;
      }
    }
  }
}
.tab-hub {
  border-bottom: 0;
  .nav-item {
    width: 33%;
    text-align: center;
    .nav-link {
      border: 1px solid #c8ced3;
      border-radius: 0;
      &.active {
        background-color: #63c2de;
        color: #fff;
        border: 1px solid #63c2de;
      }
      &:hover {
        color: #2f353a;
      }
    }
  }
}
.employee-tabcontent {
  border: 0;
  .tab-pane {
    padding: 0;
  }
}
.offwork__textarea {
  height: 100px;
  width: 100%;
  padding: 10px;
  border: 1px solid #dddddd;
  resize: none;
  border-radius: 3px;
}
.label-employee {
  text-align: right;
  line-height: 34px;
}
.img-avatar {
  img {
    width: 70%;
    border-radius: 100%;
    &:focus {
      outline: none;
    }
  }
  i {
    font-size: 130px;
    margin-bottom: 20px;
  }
}
.require-size p {
  margin-bottom: 5px;
}
.btn-editdelete {
  button {
    background-color: transparent;
    border: 0;
    &:hover {
      background-color: transparent;
    }
    i {
      font-size: 20px !important;
      font-weight: 700;
    }
  }
}
.reason-select > div{
  width: 100%;
}
.contract-current {
  tr {
    &:hover {
      cursor: pointer;
    }
  }
}
.expiration-date {
  .react-datepicker__close-icon {
    right: 40px;
    top: 70%;
  }
}
.reason-select {
  .css-26l3qy-menu {
    z-index: 9;
  }
}
.employee-datepicker {
  .react-datepicker__close-icon {
    &:after {
      background-color: transparent;
      color: #73818f;
      border: 1px solid #73818f;
      padding: 0;
    }
  }
}
.css-x8jb3b-control + div {
  z-index: 2;
}
.react-datepicker-popper {
  z-index: 3;
}
.table-row {
  text-align: left !important;
}


.breakAll {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}