.forgot-password {
  background: #424242;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  align-items: center;
  padding: 50px 100px; }
  .forgot-password__container {
    position: relative;
    z-index: 4;
    width: 100%; }
  .forgot-password__form {
    width: 100%;
    padding: 1.25rem }
    .forgot-password__form--input {
      width: 100%;
      background: #ffffff;
      border: 1px solid #bfbfbf; }
      .forgot-password__form--input::placeholder {
        color: #bfbfbf;
        font-size: 14px; }
    .forgot-password__form--button {
      border: none;
      outline: none;}
    .forgot-password__form .btn-dark {
      background: #022f37; }
  .forgot-password__background {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #022f37;
    position: absolute;
    z-index: 2; }
    .forgot-password__background img {
      position: absolute;
      filter: blur(5px);
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0.1; }
  .forgot-password__title {
    text-align: center;
    color: #23282c;
    margin-bottom: 0.5rem;
    font-size: 2.1875rem; }
  .forgot-password__paragraph {
    color: #73818f !important;
    text-align: left; }

@media only screen and (max-width: 600px) {
  .forgot-password {
    padding: 0; }
    .forgot-password__container {
      width: 100%;
      border: none; } }

@media only screen and (max-width: 800px) and (min-width: 601px) {
  .forgot-password__container {
    width: 70%; } }
