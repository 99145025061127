.add-button {
  margin-bottom: 20px;
  color: #fff;
}

.edit-button {
  margin-right: 20px;
}

.rt-td {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre-line !important;
  word-break: break-word;
}

.rt-th {
  white-space: pre-line !important;
}

.Table__itemCount {
  /* margin-top: 10px; */
  font-size: 14px;
}

.Table__pagination {
  display: flex;
  justify-content: flex-end;
  padding: 20px 10px;
}

.Table__pageButton {
  font-size: 15px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.Table__pageButton:focus {
  outline: none;
}

.Table__pageButton:disabled {
  cursor: not-allowed;
  color: gray;
}

.Table__pageButton--active {
  color: #45b3e3;
  font-weight: bold;
}


@media only screen and (max-width: 768px) {
  .ReactTable .-pagination .-center {
    -webkit-box-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }  
}

body .react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.5);
}

body .react-confirm-alert-body {
  text-align: center;
}

body .react-confirm-alert-body .react-confirm-alert-button-group {
  display: block;
  text-align: center;
}

body {
  .number-item-per-page {
    width: 300px;
    float: left;
    padding: 40px 10px;
  }
}