.form-control {
  border: 1px solid #ccc;
}
::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    #909;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
 color:    #909;
 opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
 color:    #909;
 opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color:    #909;
}
::-ms-input-placeholder { /* Microsoft Edge */
 color:    #909;
}

::placeholder { /* Most modern browsers support this now. */
 color:    #909;
}
.reset-password {
  background: #424242;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  align-items: center;
  padding: 50px 100px; }
  .reset-password__container {
    position: relative;
    z-index: 4;
    width: 100%; }
  .reset-password__form {
    width: 100%;
    padding: 30px 30px 150px 30px; }
    .reset-password__form--input::placeholder {
      color: #e0e0e0;
      font-size: 14px; }
    .reset-password__form--button {
      border: none;
      outline: none; }
  .reset-password__background {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #062a30;
    position: absolute;
    z-index: 2; }
    .reset-password__background img {
      position: absolute;
      filter: blur(5px);
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0.15; }
  .reset-password__title {
    text-align: center;
    margin-bottom: 0.5rem;
    font-size: 2.1875rem;}
  .reset-password__paragraph {
    text-align: center; }
    .reset-password__paragraph--left {
      text-align: left;
      color: #73818f !important }
  .reset-password__message--success {
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10;
    padding-top: 30; }
  .reset-password__link {
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold; }

@media only screen and (max-width: 600px) {
  .reset-password {
    padding: 0; }
    .reset-password__container {
      width: 100%;
      border: none; } }

@media only screen and (max-width: 800px) and (min-width: 601px) {
  .reset-password__container {
    width: 70%; } }
